<template>
  <b-card title="Subscription">
    <div v-if="showSubForm || subEnded">
      <b-row
        class="justify-content-center"
      >
        <b-col
          v-if="subscription.subscribed && !isFreemium"
          md="5"
        >
          <b-card
            class="popular"
            align="center"
          >
            <!-- img -->
            <b-avatar
              class="mb-1"
              size="30px"
              variant="light-success"
            >
              <feather-icon
                size="20px"
                icon="CheckCircleIcon"
              />
            </b-avatar>
            <h3>
              Lenon Ghana
            </h3>
            <strong>Account Upgrade</strong>
            <b-card-text>
              To upgrade, you can either add more modules or students to existing subscription. <b>Module Prices are all
                per month.</b> <br>
              You will be charged for the duration left for current subscription to end.
            </b-card-text>
            <strong>Current Subscription</strong>
            <b-card-text>
              <div class="d-flex flex-column text-center">
                <div class="">
                  <b>Active Modules:</b> <span> {{ activeModules }}</span>
                </div>
                <div class="">
                  <b>Total Students: </b> <span> {{ totalStudents }}</span>
                </div>
                <div class="">
                  <b>Days Left: </b> <span> {{ daysLeft }}</span>
                </div>
                <div class="">
                  <b>Per Student Amount: </b> <span> GHS{{ perStudentPrice+defaultAmount }}/Month</span>
                </div>
                <div class="">
                  <b>Flat Amount: </b> <span> GHS{{ flatPrice }}/Month</span>
                </div>
              </div>
            </b-card-text>
            <div class="d-flex justify-content-center mb-1">
              <div class="d-flex align-items-center mr-3">
                <div class="mr-1">
                  <b>Original Amount</b>
                </div>
                <div class="annual-plan">
                  <div class="plan-price mt-2">
                    <sup class="font-medium-1 font-weight-bold text-primary">₵</sup>
                    <span
                      class="pricing-basic-value font-weight-bolder text-primary"
                      style="text-decoration: line-through;"
                    >{{ totalUpgradeAmount }}</span>
                  </div>
                </div>
              </div>
              <div class="d-flex align-items-center">
                <div class="mr-1">
                  <b>Amount to pay</b>
                </div>
                <div class="annual-plan">
                  <div class="plan-price mt-2">
                    <sup class="font-medium-1 font-weight-bold text-primary">₵</sup>
                    <span class="pricing-basic-value font-weight-bolder text-primary">{{ totalUpgradeAmountDiscounted
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center mb-1">
              <div class="mr-1">
                <b>Per Student: GHS{{ perStudentDayPrice }}/Day</b>
              </div>
              <div class="mr-1">
                <b>Flat: GHS{{ flatDayPrice }}/Day</b>
              </div>
            </div>
            <div style="text-align: left !important;">
              <lenon-multi-select
                v-model="upgradeModules"
                label-name="label"
                value-name="id"
                :options="filteredModules"
                placeholder="Select Modules"
                label="Select Additional Modules"
              />
            </div>
            <div
              class="mt-1"
              style="text-align: left !important;"
            >
              <lenon-input
                v-model="upgradeStudents"
                placeholder="Enter Total Students"
                label="Enter Total Students"
                :suffix-icon="null"
              />
            </div>
            <div class="d-flex justify-content-between mt-2">
              <lenon-button
                variant="outline-danger"
                icon="XIcon"
                label="Cancel"
                :disabled="loading"
                @onClick="toggle()"
              />
              <lenon-button
                variant="primary"
                label="Proceed"
                :loading="loading"
                :disabled="totalUpgradeAmountDiscounted<=0"
                @onClick="upgrade()"
              />
            </div>
          </b-card>
        </b-col>
        <b-col
          v-else
          md="5"
        >
          <b-card
            class="popular"
            align="center"
          >
            <!-- img -->
            <b-avatar
              class="mb-1"
              size="30px"
              variant="light-success"
            >
              <feather-icon
                size="20px"
                icon="CheckCircleIcon"
              />
            </b-avatar>
            <h3>
              Lenon Ghana
            </h3>
            <strong>Account Subscription</strong>
            <b-card-text>
              Select any number of additional module for your school. <b>Module Prices are all per month.</b> <br>
              The default modules (Dashboard, Administration, Students, Accounts) come at a cost of {{ defaultAmount
              }}/student/month.
              <br>Documents module comes with <b>2M</b> input and <b>1M</b> output credits for generating lesson notes and questions.
            </b-card-text>
            <div class="d-flex justify-content-center mb-1">
              <div class="d-flex align-items-center mr-3">
                <div class="mr-1">
                  <b>Original Amount</b>
                </div>
                <div class="annual-plan">
                  <div class="plan-price mt-2">
                    <sup class="font-medium-1 font-weight-bold text-primary">₵</sup>
                    <span
                      class="pricing-basic-value font-weight-bolder text-primary"
                      style="text-decoration: line-through;"
                    >{{ totalAmount }}</span>
                  </div>
                </div>
              </div>
              <div class="d-flex align-items-center">
                <div class="mr-1">
                  <b>Amount to pay</b>
                </div>
                <div class="annual-plan">
                  <div class="plan-price mt-2">
                    <sup class="font-medium-1 font-weight-bold text-primary">₵</sup>
                    <span class="pricing-basic-value font-weight-bolder text-primary">{{ totalAmountDiscounted }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center mb-1">
              <div class="mr-1">
                <b>Default Per Student: GHS{{ defaultAmount }}/Month</b>
              </div>
              <div class="mr-1">
                <b>Per Student: GHS{{ perStudentPrice }}/Month</b>
              </div>
              <div class="mr-1">
                <b>Flat: GHS{{ flatPrice }}/Month</b>
              </div>
            </div>
            <div style="text-align: left !important;">
              <lenon-multi-select
                v-model="selectedModules"
                label-name="label"
                value-name="id"
                :options="modules"
                placeholder="Select Modules"
                label="Select Additional Modules"
                :suffix-icon="null"
              />
            </div>
            <div
              class="mt-1"
              style="text-align: left !important;"
            >
              <lenon-input
                v-model="total_students"
                placeholder="Enter Total Students"
                label="Enter Total Students"
                :suffix-icon="null"
              />
            </div>
            <div
              class="mt-1"
              style="text-align: left !important;"
            >
              <lenon-input
                v-model="duration"
                placeholder="Enter Duration in Months"
                label="Subscription Duration (Months)"
                :suffix-icon="null"
              />
            </div>
            <div class="mt-1">
              <strong>Discounts</strong>
            </div>
            <div class="text-danger">
              Depending on the number of months you subscribe for, you get a sepcific number of months off.
              Below is a breakdown of the total number of subscription duration against the total number of months you get
              off.
            </div>
            <div class="mt-1 d-flex justify-content-between">
              <b>4+ / 1 month off</b>
              <b>8+ / 2 months off</b>
              <b>12+ / 3 months off</b>
            </div>
            <div class="d-flex justify-content-between mt-2">
              <lenon-button
                v-if="!subEnded"
                variant="outline-danger"
                icon="XIcon"
                label="Cancel"
                :disabled="loading"
                @onClick="toggle()"
              />
              <div v-else />
              <lenon-button
                variant="primary"
                :label="'Proceed'"
                :loading="loading"
                :disabled="totalAmountDiscounted <= 0"
                @onClick="subscribe()"
              />
            </div>
          </b-card>
        </b-col>
      </b-row>
    </div>
    <div v-else>
      <b-row
        v-if="subscription.subscribed && !isFreemium"
        class="justify-content-center"
      >
        <b-col md="5">
          <b-card
            class="popular"
            align="center"
          >
            <!-- img -->
            <b-avatar
              class="mb-1"
              size="30px"
              variant="light-success"
            >
              <feather-icon
                size="20px"
                icon="CheckCircleIcon"
              />
            </b-avatar>
            <h3>
              Lenon Ghana
            </h3>
            <strong>SUBSCRIBED</strong>
            <b-card-text>
              You are currently subscribed on Lenon Ghana. <br>
              <div class="d-flex flex-column text-center">
                <div class="">
                  <b>Active Modules:</b> <span> {{ activeModules }}</span>
                </div>
                <div class="">
                  <b>Total Students: </b> <span> {{ totalStudents }}</span>
                </div>
                <div class="">
                  <b>Amount Paid: </b> <span> {{ discountedPricePaid }}</span>
                </div>
                <div class="">
                  <b>Ends On: </b> <span> {{ subscriptionEndDate }}</span>
                </div>
              </div>
            </b-card-text>
            <lenon-button
              label="Upgrade"
              @onClick="toggle()"
            />
          </b-card>
        </b-col>
      </b-row>
      <b-row
        v-if="subscription.on_trial"
        class="justify-content-center"
      >
        <b-col md="5">
          <b-card
            class="popular"
            align="center"
          >
            <!-- img -->
            <b-avatar
              class="mb-1"
              size="30px"
              variant="light-success"
            >
              <feather-icon
                size="20px"
                icon="CheckCircleIcon"
              />
            </b-avatar>
            <h3>
              Lenon Ghana
            </h3>
            <strong>TRIAL</strong>
            <b-card-text>
              You are currently on trial period. <br>
              <div class="d-flex flex-column text-center">
                <div class="">
                  <b>Active Modules:</b> <span> {{ activeModules }}</span>
                </div>
                <div class="">
                  <b>Ends On: </b> <span> {{ trialEnds }}</span>
                </div>
              </div>
            </b-card-text>
            <lenon-button
              label="Subscribe"
              :disabled="loading"
              @onClick="toggle()"
            />
          </b-card>
        </b-col>
      </b-row>
      <b-row
        v-if="isFreemium"
        class="justify-content-center"
      >
        <b-col md="5">
          <b-card
            class="popular"
            align="center"
          >
            <!-- img -->
            <b-avatar
              class="mb-1"
              size="30px"
              variant="light-success"
            >
              <feather-icon
                size="20px"
                icon="CheckCircleIcon"
              />
            </b-avatar>
            <h3>
              Lenon Ghana
            </h3>
            <strong>FREEMIUM</strong>
            <b-card-text>
              You are currently using a limited version of Lenon with Ads. <br>
              <div class="d-flex flex-column text-center">
                <div class="">
                  <b>Active Modules:</b> <span> {{ activeModules }}</span>
                </div>
                <div class="">
                  <b>Total Students: </b> <span> {{ totalStudents }}</span>
                </div>
              </div>
            </b-card-text>
            <lenon-button
              label="Subscribe"
              :disabled="loading"
              @onClick="toggle()"
            />
          </b-card>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BRow, BCol, BCard, BCardText, BAvatar, BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import LenonInput from '@/lenon/components/LenonInput.vue'
import LenonMultiSelect from '@/lenon/components/LenonMultiSelect.vue'
import showToast from '@/lenon/mixins/showToast'
import confirm from '@/lenon/mixins/confirm'
import LenonButton from '@/lenon/components/LenonButton.vue'

export default {
  components: {
    LenonButton,
    LenonMultiSelect,
    LenonInput,
    BCardText,
    BRow,
    BCol,
    BCard,
    BAvatar,
    BBadge,
  },
  directives: {
    Ripple,
  },
  mixins: [showToast, confirm],
  data() {
    return {
      status: 'monthly',
      monthlyPlanShow: true,
      showSubForm: false,
      loading: false,
      total_students: null,
      upgradeStudents: null,
      selectedPricingId: null,
      defaultAmount: 0.6,
      duration: null,
      durationInDays: 0,
      studentsCap: Number.MAX_SAFE_INTEGER,
      upgradeMode: false,
      upgradeFromFreemium: false,
      selectedModules: [],
      upgradeModules: [],
      modules: [],
    }
  },
  computed: {
    subEnded() {
      return !(this.subscription.subscribed || this.subscription.on_trial || this.subscription.freemium)
    },
    filteredModules() {
      return this.modules.filter(m => !this.selectedModules.map(sm => sm.id).includes(m.id))
    },
    perStudentDayPrice() {
      return (this.upgradeModules.filter(m => m.type === 'per_student').map(m => +m.price).reduce((a, b) => a + b, 0) / 30).toFixed(3)
    },
    flatDayPrice() {
      return (this.upgradeModules.filter(m => m.type === 'flat').map(m => +m.price).reduce((a, b) => a + b, 0) / 30).toFixed(3)
    },
    perStudentPrice() {
      return +this.selectedModules.filter(m => m.type === 'per_student').map(m => +m.price).reduce((a, b) => a + b, 0).toFixed(1)
    },
    flatPrice() {
      return this.selectedModules.filter(m => m.type === 'flat').map(m => +m.price).reduce((a, b) => a + b, 0)
    },
    totalStudents() {
      return this.$store.getters['auth/totalStudents'] || 0
    },
    subscriptionEnded() {
      if (this.subscription) {
        return !this.subscription.on_trial && !this.subscription.subscribed
      }
      return true
    },
    totalPerAmount() {
      const total = +this.total_students
      return ((total > 500 ? 500 : total || 0) * (this.perStudentPrice + this.defaultAmount) * (+this.duration || 0))
    },
    totalFlatAmount() {
      return (this.flatPrice * (+this.duration || 0))
    },
    totalAmount() {
      return (this.totalFlatAmount + this.totalPerAmount).toFixed(1)
    },
    discountedDuration() {
      let duration = +this.duration
      if (duration >= 4) {
        const perfectByFour = duration - (duration % 4)
        const monthsOff = perfectByFour / 4
        duration -= monthsOff
      }
      return duration
    },
    discountedDurationInDays() {
      let duration = +this.durationInDays
      const disCDays = (4 * 30)
      if (duration >= disCDays) {
        const perfectByDiscDays = duration - (duration % disCDays)
        const daysOff = perfectByDiscDays / disCDays
        duration -= (daysOff * 30)
      }
      return duration
    },
    totalPerAmountDiscounted() {
      const total = +this.total_students
      return ((total > 500 ? 500 : total || 0) * (this.perStudentPrice + this.defaultAmount) * (+this.discountedDuration || 0))
    },
    totalFlatAmountDiscounted() {
      return (this.flatPrice * (+this.discountedDuration || 0))
    },
    totalAmountDiscounted() {
      return (this.totalFlatAmountDiscounted + this.totalPerAmountDiscounted).toFixed(1)
    },
    // Upgrades
    totalStudentsUpgradeAmount() {
      return ((+this.upgradeStudents || 0) * ((+this.perStudentPrice + this.defaultAmount) / 30) * (+this.durationInDays || 0))
    },
    totalPerStudentModulesUpgradeAmount() {
      return (((+this.totalStudents) + (+this.upgradeStudents)) * (+this.perStudentDayPrice) * (+this.durationInDays || 0))
    },
    totalFlatModulesUpgradeAmount() {
      return ((+this.flatDayPrice) * (+this.durationInDays || 0))
    },
    totalStudentsUpgradeAmountD() {
      return ((+this.upgradeStudents || 0) * ((+this.perStudentPrice + this.defaultAmount) / 30) * (+this.discountedDurationInDays || 0))
    },
    totalPerStudentModulesUpgradeAmountD() {
      return (((+this.totalStudents) + (+this.upgradeStudents)) * (+this.perStudentDayPrice) * (+this.discountedDurationInDays || 0))
    },
    totalFlatModulesUpgradeAmountD() {
      return ((+this.flatDayPrice) * (+this.discountedDurationInDays || 0))
    },
    totalUpgradeAmount() {
      return (this.totalStudentsUpgradeAmount + this.totalPerStudentModulesUpgradeAmount + this.totalFlatModulesUpgradeAmount).toFixed(1)
    },
    totalUpgradeAmountDiscounted() {
      return (this.totalStudentsUpgradeAmountD + this.totalPerStudentModulesUpgradeAmountD + this.totalFlatModulesUpgradeAmountD).toFixed(1)
    },
    subscription() {
      return this.$store.getters['auth/subscription']
    },
    isFreemium() {
      return this.subscription.freemium
    },
    trialEnds() {
      return this.subscription.trial_ends
    },
    activeModules() {
      return this.subscription?.modules?.map(m => m.toUpperCase()).join(', ')
    },
    discountedPricePaid() {
      return (this.subscription.discounted_amount || 0).toFixed(1)
    },
    subscriptionEndDate() {
      return this.subscription.ends
    },
    daysLeft() {
      return this.subscription.days_left || 0
    },
  },
  watch: {
    subscription(sub) {
      this.total_students = sub.total_students || 0
      this.selectedModules = this.modules.filter(m => sub.modules?.includes(m.id))
      this.duration = sub.duration || 0
      this.durationInDays = sub.days_left || 0
    },
  },
  created() {
    this.total_students = this.subscription?.total_students || 0
    this.getCurrentSub()
  },
  methods: {
    upgrade() {
      this.loading = true
      this.$http.post('accounts/upgrade', {
        modules: this.upgradeModules.map(m => m.id),
        total_students: +this.upgradeStudents,
      })
        .then(res => {
          const link = document.createElement('a')
          link.href = res.data
          // link.target = "_blank"
          link.click()
        })
        .catch(err => {
          this.showError('Failed to upgrade subscription')
        })
        .finally(() => {
          this.loading = false
        })
    },
    toggle() {
      this.showSubForm = !this.showSubForm
    },
    subscribe() {
      this.confirmAction()
        .then(res => {
          if (res) {
            this.loading = true
            this.$http.post('accounts/subscribe', {
              duration: +this.duration,
              modules: this.selectedModules.map(m => m.id),
              total_students: +this.total_students,
            })
              .then(res => {
                const link = document.createElement('a')
                link.href = res.data
                // link.target = "_blank"
                link.click()
              })
              .catch(err => {
                this.showError('Failed to initiate subscription')
              })
              .finally(() => {
                this.loading = false
              })
          }
        })
    },
    getCurrentSub() {
      this.$http.get('accounts/current-subscription')
        .then(res => {
          this.modules = res.data?.modules
          this.$store.commit('auth/setSubAndPricing', res.data?.subscription)
        })
        .catch(err => {
          this.showError('Failed to load subscription')
        })
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-pricing.scss';
</style>
